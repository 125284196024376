import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis, oidcAuthApiRef } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog, SignInPage } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { ExplorePage } from '@backstage-community/plugin-explore';
import { RELATION_API_CONSUMED_BY, RELATION_API_PROVIDED_BY, RELATION_CONSUMES_API, RELATION_DEPENDENCY_OF, RELATION_DEPENDS_ON, RELATION_HAS_PART, RELATION_PART_OF, RELATION_PROVIDES_API } from '@backstage/catalog-model';

import type { IdentityApi } from '@backstage/core-plugin-api';
import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';

import { setTokenCookie } from './cookieAuth';
import { UnifiedThemeProvider, createBaseThemeOptions, createUnifiedTheme, genPageTheme, palettes, shapes } from '@backstage/theme';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import Brightness7Icon from '@material-ui/icons/Brightness7';

import { EntityValidationPage } from '@backstage-community/plugin-entity-validation';

const inherentDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: palettes.dark
  }),
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#4B5FFF', '#00CDAF'], shape: shapes.wave }),
  }
});

const inherentLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: palettes.light
  }),
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#4B5FFF', '#00CDAF'], shape: shapes.wave }),
  }
});

const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
      <SignInPage
        {...props}
        auto
        provider={{
          id: 'oidc',
          title: 'OIDC',
          message: 'Sign in using OIDC',
          apiRef: oidcAuthApiRef
        }}
        onSignInSuccess={async (identityApi: IdentityApi) => {
          setTokenCookie(
            await discoveryApi.getBaseUrl('cookie'),
            identityApi,
          );

          props.onSignInSuccess(identityApi);
        }}
      />
      );
    }
  },
  themes: [{
    id: 'inherent-dark-theme',
    title: 'Dark theme',
    variant: 'dark',
    icon: <Brightness3Icon />,
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={inherentDarkTheme} children={children}>
      </UnifiedThemeProvider>
    ),
  },
  {
    id: 'inherent-light-theme',
    title: 'Light theme',
    variant: 'light',
    icon: <Brightness7Icon />,
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={inherentLightTheme} children={children}>
      </UnifiedThemeProvider>
    ),
  }]
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/explore" element={<ExplorePage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage initialState={{
        selectedKinds: ['component', 'system', 'api', 'resource'],
        selectedRelations: [
          RELATION_CONSUMES_API,
          RELATION_API_CONSUMED_BY,
          RELATION_PROVIDES_API,
          RELATION_API_PROVIDED_BY,
          RELATION_HAS_PART,
          RELATION_PART_OF,
          RELATION_DEPENDS_ON,
          RELATION_DEPENDENCY_OF,
        ],
        maxDepth: 1
      }} />} />
    <Route path="/entity-validation" element={<EntityValidationPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
